import type { ResponseBody } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Candidates/GET';
import { GET_CANDIDATES_REST } from '~/constants';
import { execute } from '~/services/utils';

/**
 * @name getAllCandidates
 * @description Get all Candidates
 */
const getCandidates = async (config?: {
  perPage?: number;
  page?: number;
  search?: string;
}): Promise<ResponseBody['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBody['data']>(
    apiClient.get(
      GET_CANDIDATES_REST +
        `${config?.search ? `?search=${config?.search}` : ''}${
          config?.perPage ? `?per_page=${config?.perPage}` : ''
        }${config?.page ? `&page=${config?.page}` : ''}`
    )
  );
};

export default {
  getCandidates
};

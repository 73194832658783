import { storeToRefs } from 'pinia';
import type { ResponseBody } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Candidates/GET';
import type {
  Candidate,
  SkillsCategories,
  CandidateItems,
  CandidateItemToUpdate,
  CandidateEvents,
  CandidateStats
} from '@salvatore0193/definitions/lib/Candidate';
import type { ResponsePagination } from '@salvatore0193/definitions';
import { useCandidateState } from '~/stores/candidates/state';
import type { CandidateState } from '~/stores/candidates/state';
import CandidatesServices from '~/services/admin/candidates';
import CandidateServices from '~/services/admin/candidate';
import TalentServices from '~/services/talent';
import { sessionTTL } from '~/constants';

function isCandidateList(
  data: ResponsePagination<Candidate[]> | Candidate[]
): data is Candidate[] {
  return Array.isArray(data);
}

export const useCandidatesActions = defineStore('candidates.actions', () => {
  const { settings } = useSettings();
  const {
    candidates,
    pagination,
    counts,
    cachedResult,
    isSearched,
    candidate,
    skillsCategories,
    candidateItems,
    candidateStats
  } = storeToRefs(useCandidateState());

  const changePage = async (page: number): Promise<void> => {
    await getCandidates(page);

    pagination.value.currentPage = page;
  };

  const updateCandidates = (
    c: ResponsePagination<Candidate[]> | Candidate[]
  ): void => {
    if (isCandidateList(c)) {
      candidates.value = c;
      pagination.value.currentPage = 0;
      pagination.value.total = 1;
      pagination.value.perPage = 8;
      return;
    }

    candidates.value = c.data;
    updatePagination(c);
  };

  const updatePagination = (p: ResponsePagination<Candidate[]>): void => {
    pagination.value.total = p.last_page - 1;
    pagination.value.perPage = Number(p.per_page);
  };

  const updateCounts = (c: CandidateState['counts']): void => {
    counts.value = c;
  };

  const updateCacheResult = (timestamp: number): void => {
    cachedResult.value = timestamp;
  };

  /**
   * @deprecated
   * The response of this call by the way is huge and LARAVEL falls apart
   */
  async function getAllCandidates(): Promise<Candidate[] | undefined> {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CandidatesServices.getCandidates();

    if (response && Array.isArray(response.list)) {
      candidates.value = response.list;
      return response.list;
    }
  }

  /**
   * @description Get candidates list from API.
   * We have a "withPage" param to force update the pagination.
   * @param withPage
   * @param searchValue
   * @param isSearchServerSide
   */
  async function getCandidates(
    withPage?: number,
    searchValue?: string,
    isSearchServerSide?: boolean
  ): Promise<ResponseBody['data'] | undefined> {
    /**
     * This is a check to verify if a user is logged.
     */
    if (!settings.loggedIn) {
      return undefined;
    }

    /**
     * @name Searching by server
     * I add a flag at momento to turn on / off this function
     */
    if (isSearchServerSide && searchValue && !isSearched.value) {
      const response = await CandidatesServices.getCandidates({
        perPage: undefined,
        page: undefined,
        search: searchValue
      });

      if (response) {
        updateCandidates(response.list);
      }
      return;
    }

    /**
     * If we have a "searchValue", we need:
     * - to have all candidates list at first time;
     * - remove pagination (from UI);
     * - use the cache for other searches.
     */
    if (searchValue && !isSearched.value) {
      const allCandidates = await CandidatesServices.getCandidates();

      if (allCandidates) {
        const { list, counts } = allCandidates;

        if (Array.isArray(list)) {
          isSearched.value = true;

          /**
           * TODO: Remove this when we have the server side search
           */
          const filterList = list.filter((el) => {
            const rowValues = Object.values(el)
              .join(' ')
              .toLowerCase()
              .replace(/[^\w\s]/gi, '');

            return rowValues.includes(searchValue.toLowerCase());
          });

          updateCandidates(filterList);
        }

        if (counts) {
          updateCounts(counts);
        }
      }
      /**
       * Invalidate the cache.
       */
      updateCacheResult(0);
      return;
    }

    /**
     * This is a check to verify if the cached result is still valid Or
     * If we have a "searchValue" cached, we need to use the cache.
     */
    if (
      (!withPage &&
        cachedResult.value &&
        cachedResult.value + sessionTTL < Date.now()) ||
      (searchValue && isSearched)
    ) {
      updateCandidates(candidates.value);
      return;
    }

    /**
     * @description CmPagination has a index array from 0, so we call "page + 1"
     */
    function getCurrentPage() {
      if (withPage === 0) return 1;

      if (withPage) {
        return withPage + 1;
      }

      if (pagination.value.currentPage) {
        return pagination.value.currentPage + 1;
      }

      return 1;
    }

    const currentPage = getCurrentPage();

    /**
     * TODO: Add searchValue to the request with some filters. (Maybe v1)
     * es: const search = searchValue ? `?search=${searchValue}` : '';
     * Actually, the searchValue is client side.
     */
    const allCandidates = await CandidatesServices.getCandidates({
      perPage: pagination.value.perPage,
      page: currentPage
    });

    if (allCandidates) {
      const { list, counts } = allCandidates;

      isSearched.value = false;
      updateCandidates(list);
      updateCounts(counts);
      updateCacheResult(Date.now());
    }
  }

  const getCandidate = async (id: string): Promise<Candidate | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    if (candidate && candidate.value?.id === id) {
      return candidate.value;
    }

    const response = await CandidateServices.getCandidate(id);

    if (response) {
      candidate.value = response;
      return response;
    }
  };

  const updateCandidatesArray = (index: number, candidate: Candidate) => {
    candidates.value[index] = candidate;
  };

  const updateCandidate = async (
    userId: string,
    candidateObj: Partial<Candidate>
  ): Promise<Candidate | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CandidateServices.updateCandidate(
      userId,
      candidateObj
    );

    if (response) {
      candidate.value = response;
      return response;
    }
  };

  const updateCandidateItem = async (
    userId: string,
    item: CandidateItemToUpdate
  ): Promise<Candidate | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CandidateServices.updateCandidateItem(userId, item);

    if (response) {
      candidate.value = response;
      return response;
    }
  };

  const getSkills = async (): Promise<SkillsCategories[] | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    if (skillsCategories && skillsCategories.value) {
      return skillsCategories.value;
    }

    const response = await TalentServices.getSkillCategories();

    if (response) {
      skillsCategories.value = response;
      return response;
    }
  };

  const getCandidateItems = async (): Promise<CandidateItems | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    if (candidateItems && candidateItems.value) {
      return candidateItems.value;
    }

    const response = await TalentServices.getCandidateItems();

    if (response) {
      candidateItems.value = response;
      return response;
    }
  };

  const getCandidateJobsStats = async (
    id: string
  ): Promise<CandidateStats | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CandidateServices.getCandidateStats(id);

    if (response) {
      candidateStats.value = response;
      return candidateStats.value;
    }
  };

  async function deleteCandidate(id: string) {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CandidateServices.deleteCandidate(id);

    if (response === 'Candidate deleted') {
      candidate.value = null;
      return response;
    }
  }

  async function postComment(id: string, comment: Partial<CandidateEvents>) {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CandidateServices.postComment(id, comment);

    if (response) {
      return response;
    }
  }

  return {
    changePage,
    getCandidates,
    getCandidate,
    getSkills,
    getCandidateItems,
    getCandidateJobsStats,
    updateCandidate,
    updateCandidateItem,
    deleteCandidate,
    updateCandidatesArray,
    postComment,
    getAllCandidates
  };
});

import type { AccordionItem, SelectItem } from '@emotioncod/cm-design-system';
import type { StatCardData } from '@emotioncod/cm-design-system/dist/types/types';
import type {
  Candidate,
  CandidateEvents,
  CandidateFocus,
  CandidateItems,
  CandidateJobData,
  CandidateJobSoon,
  CandidateRemoteness,
  CandidateRole,
  IdealCompany,
  SkillsCategories,
  CandidateDesiredRole,
  IdealCompanyBase,
  CandidateStats
} from '@salvatore0193/definitions/lib/Candidate';
import type {
  CompanyItems,
  KeyValuesIndustry
} from '@salvatore0193/definitions/lib/Companies';
import type { JobRole } from '@salvatore0193/definitions/lib/Jobs';
import type { EventMatch, Match } from '@salvatore0193/definitions/lib/Matches';
import { Languages } from '@salvatore0193/definitions/lib/Jobs/const';
import { EventType } from '@salvatore0193/definitions/lib/Matches/const';
import { formatDate } from '~/utils';

export const mapListNotes = (
  events: CandidateEvents[]
): (Partial<EventMatch> & { authorName: string; typeEvent: string })[] => {
  if (!events) return [];

  return events.map((event) => {
    const authorName = event.author?.name ? event.author.name : 'Unknown';

    return {
      id: event.id ? event.id : '',
      typeEvent: event.type,
      source: event.source,
      content: event.content ? event.content : '',
      date: event.date ? formatDate(event.date) : '',
      authorName,
      deleted_at: event?.deleted_at ? event.deleted_at : ''
    };
  });
};

export const mapListNotesToMatch = (
  events: Partial<EventMatch>[],
  jobPositionName: string,
  companyName?: string
): (Partial<EventMatch> & {
  authorName: string;
  typeEvent: string;
  jobPositionName: string;
  companyName?: string;
})[] => {
  if (!events) return [];

  return events.map((event) => {
    const authorName = event.author?.name ? event.author.name : 'Unknown';

    /**
     * @see event.date?.$date?.$numberLong
     * This is a temporany solution
     * TODO: remove this when clean the data
     */
    return {
      id: event.id ? event.id : '',
      typeEvent: event.type ? event.type.toString() : '',
      source: event.source,
      content: event.content ? event.content : '',
      // @ts-ignore
      date: event.date?.$date?.$numberLong // @ts-ignore
        ? formatDate(event.date.$date.$numberLong, true)
        : event.date
        ? formatDate(event.date, false)
        : '',
      authorName,
      deleted_at: event.deleted_at ? event.deleted_at : '',
      jobPositionName,
      companyName
    };
  });
};

export function candidateNoteResolve(
  userId: string,
  candidates: Candidate[],
  candidate: Candidate | null,
  matchId: string,
  matches: Match[]
) {
  const match = matches.find((m) => m.id === matchId);

  const cand = candidates.find((c) => c.id === userId) || candidate;

  if (match) {
    return {
      fullName: `${match.candidate?.name} ${match.candidate?.surname}`,
      list: mapListNotesToMatch(
        match.events,
        match.jobPosition?.name || '',
        match.company?.name
      )
    };
  }

  if (cand) {
    return {
      fullName: `${cand.name} ${cand.surname}`,
      list: mapListNotes(cand.events)
    };
  }
}

export function resolveCandidateStats(
  candidate: Candidate,
  t: (key: string, ...args: any[]) => string
): StatCardData[] {
  if (!candidate) return [];
  return [
    {
      statName: t('candidates.details.stats.readyToWork'),
      iconName: 'mdiCalendarBlank',
      statValue: candidate.job.time_expectations?.name || '',
      iconColor: 'purple'
    },
    {
      statName: t('candidates.details.stats.desiredSalary'),
      iconName: 'mdiCurrencyEur',
      statValue: candidate.job.salary.min || 0,
      iconColor: 'blue'
    },
    {
      statName: t('candidates.details.stats.workPref'),
      iconName: 'mdiHomeAutomation',
      statValue: candidate.job.idealCompany.type?.name || '',
      iconColor: 'red'
    },
    {
      statName: t('candidates.details.stats.seniority'),
      iconName: 'mdiSignalCellular3',
      statValue: candidate.job.levelOfExperience || 0,
      iconColor: 'green'
    }
  ];
}

/**
 * Resolve the Candidate statistics tab data
 * Todo: check data values on the Candidate object
 * @param stats
 * @param t
 */
export function resolveCandidateStatistics(
  stats: CandidateStats,
  t: (key: string, ...args: any[]) => string
): StatCardData[] {
  if (!stats) return [];
  return [
    {
      statName: t('candidates.stats.totalMatches'),
      iconName: 'mdiBriefcaseCheck',
      statValue: stats.totalMatches || 0,
      iconColor: 'green'
    },
    {
      statName: t('candidates.stats.acceProposals'),
      iconName: 'mdiCheckCircle',
      statValue: stats.acceptedProposals || 0,
      iconColor: 'blue'
    },
    {
      statName: t('candidates.table.openSelection'),
      iconName: 'mdiTargetAccount',
      statValue: stats.openSelections || 0,
      iconColor: 'red'
    },
    {
      statName: t('candidates.stats.offers'),
      iconName: 'mdiSwapHorizontal',
      statValue: stats.offers || 0,
      iconColor: 'green'
    }
  ];
}

function resolveSkillIcon(
  skillId: number,
  techStackIconData: any
): {
  icon: string;
  backgroundColorIcon: string;
} {
  return {
    icon: techStackIconData(skillId).path,
    backgroundColorIcon: techStackIconData(skillId).color
  };
}

export function resolveCandidateSkillsSelect(
  skillsCategories: SkillsCategories[],
  techStackIconData: any
): SelectItem[] {
  if (!skillsCategories) return [];

  return skillsCategories.map((skill) => {
    const skillIcon = resolveSkillIcon(skill.id, techStackIconData);

    return {
      text: skill.name,
      value: skill.id,
      disabled: false,
      icon: skillIcon.icon ? skillIcon.icon : '/skill-default.svg',
      backgroundColorIcon: skillIcon.backgroundColorIcon
        ? skillIcon.backgroundColorIcon
        : 'rgb(0, 0, 0)'
    };
  });
}

export function resolveSkillFromId(
  skillId: number,
  skillsCategories: SkillsCategories[]
): SkillsCategories {
  if (!skillsCategories || !skillId) return { id: skillId, name: '' };

  const skillObj = skillsCategories.find((skill) => skill.id === skillId);

  return {
    name: skillObj?.name ? skillObj?.name : '',
    id: skillObj?.id ? skillObj?.id : skillId
  };
}

export function resolveCategoryName(
  categoryId: string,
  skillsCategories: SkillsCategories[]
): string {
  const category = skillsCategories.find(
    (skill) => skill.category_id === categoryId
  );

  return category?.category_name ? category.category_name : '';
}

export function resolveSkillValue(
  skills: SkillsCategories[],
  skillsCategories: SkillsCategories[]
): { id: number; text: string }[] {
  return skills.map((skill) => {
    const skillObj = skillsCategories.find((e) => e.id === skill.id);

    return {
      id: skillObj?.id ? skillObj?.id : skill.id,
      text: skillObj?.name ? skillObj?.name : ''
    };
  });
}

export function resolveIdealCompanyValuesFromId(
  valueId: number,
  candidateFocus: CandidateFocus[]
): { name: string; id: number } {
  if (!candidateFocus || !valueId) return { id: valueId, name: '' };

  const valObj = candidateFocus.find((val) => val.id === valueId);

  return {
    id: valObj?.id ? valObj.id : valueId,
    name: valObj?.name ? valObj.name : ''
  };
}

export function resolveDesiredRoles(
  candidateItems: CandidateItems | CandidateJobData['desiredRoles']
): SelectItem[] {
  if (!candidateItems) return [];

  if (Array.isArray(candidateItems)) {
    return (candidateItems as CandidateJobData['desiredRoles']).map((role) => {
      return {
        text: role.name,
        value: role.id
      };
    });
  } else {
    return (candidateItems as CandidateItems)?.candidateRole.map((role) => {
      return {
        text: role.name,
        value: role.id
      };
    });
  }
}

export function resolveDesiredRolesValue(
  roles: CandidateDesiredRole[] | JobRole[],
  candidateRole: CandidateRole[]
): { id: number; text: string }[] {
  function isDesiredRole(
    data: CandidateDesiredRole | JobRole
  ): data is CandidateDesiredRole {
    return typeof data === 'object' && !('seniority' in data);
  }

  return roles.map((role) => {
    const roleObj = candidateRole.find((e) => e.id === role.id);
    const seniority = isDesiredRole(role) ? role?.seniority : '';

    return {
      id: roleObj?.id ? roleObj?.id : role.id,
      text: roleObj?.name ? roleObj?.name : '',
      seniority
    };
  });
}

export function resolveRemotenessItems(
  candidateItems: CandidateItems | IdealCompany['type']
): SelectItem[] {
  if (!candidateItems) return [];

  if ('name' in candidateItems) {
    return [
      {
        text: (candidateItems as IdealCompany['type'])?.name || '',
        value: (candidateItems as IdealCompany['type'])?.id
      }
    ];
  } else {
    return (candidateItems as CandidateItems)?.candidateRemoteness?.map(
      (role) => {
        return {
          text: role.name,
          value: role.id
        };
      }
    );
  }
}

export function resolveRemotenessValues(
  values: IdealCompany['type'] | SelectItem[],
  idealCompanyTypeArr: CandidateRemoteness[]
): SelectItem[] {
  if (!values) return [];

  return values.map((val) => {
    const typeObject = idealCompanyTypeArr.find(
      (el) => el.id === (val?.value ? val?.value : val.id)
    );

    return {
      text: typeObject?.name || '',
      value: typeObject?.id || val.id
    };
  });
}

export function resolveIdealCompanyTypeById(
  details: (number | { text: string; value: number })[],
  idealCompanyTypeArr: CandidateRemoteness[]
): IdealCompany['type'] {
  if (!details) return [];

  return details.map((detail) => {
    const typeObject = idealCompanyTypeArr.find(
      (el) => el.id === (typeof detail === 'number' ? detail : detail.value)
    );

    return {
      id:
        typeObject?.id || (typeof detail === 'number' ? detail : detail.value),
      name: typeObject?.name || ''
    };
  });
}

export function resolveCityItemsValues(item: CandidateItems) {
  return (item as CandidateItems)?.candidateJobCity.map((city) => {
    return {
      text: city.it,
      value: city.id
    };
  });
}

export function resolveTimeExpectationsItems(
  candidateItems: CandidateItems
): SelectItem[] | string {
  if (!candidateItems) return [];

  return (candidateItems as CandidateItems)?.candidateJobSoon.map((time) => {
    return {
      text: time.name,
      value: time.id
    };
  });
}

export function resolveTimeExpectationsValue(
  values: CandidateJobData['time_expectations'],
  candidateJobSoon: CandidateJobSoon[]
): { text: string; value: number } | undefined {
  if (!values || !candidateJobSoon) return;

  const valueObject = candidateJobSoon.find((el) => el.id === values.id);

  if (valueObject) {
    return {
      value: 1,
      text: 'test'
    };
  }
}

export function resolveFocusItems(
  candidateItems: CandidateItems | IdealCompanyBase['values']
): SelectItem[] {
  if (!candidateItems) return [];

  if ('values' in candidateItems) {
    return (candidateItems as IdealCompanyBase['values']).map((focus) => {
      return {
        text: focus.name,
        value: focus.id
      };
    });
  } else {
    return (candidateItems as CandidateItems)?.candidateFocus.map((focus) => {
      return {
        text: focus.name,
        value: focus.id
      };
    });
  }
}

export function resolveFocusItemsValues(
  values: IdealCompanyBase['values'],
  candidateFocus: CandidateFocus[]
): { id: number; text: string }[] {
  return values.map((val) => {
    const valObj = candidateFocus.find((e) => e.id === val.id);

    return {
      id: val.id,
      text: val?.name ? val.name : valObj?.name ? valObj?.name : ''
    };
  });
}

export function resolveDimensionItems(
  companyItems: CompanyItems | IdealCompany['dimension']
): SelectItem[] | string {
  if (!companyItems) return [];

  if (
    companyItems &&
    Array.isArray((companyItems as CompanyItems).companySize)
  ) {
    return (companyItems as CompanyItems).companySize.map((size) => {
      return {
        text: size.name,
        value: size.id
      };
    });
  } else {
    return (companyItems as IdealCompany['dimension'])?.name || '';
  }
}

export function resolveFocusCompanyItems(
  companyItems: CompanyItems | IdealCompany['focus']
): SelectItem[] | string {
  if (!companyItems) return [];

  if (
    companyItems &&
    Array.isArray((companyItems as CompanyItems)?.companyFocus)
  ) {
    return (companyItems as CompanyItems)?.companyFocus?.map((focus) => {
      return {
        text: focus.name,
        value: focus.id
      };
    });
  } else {
    return (companyItems as { id: number; name: string }[])
      .map((focus) => focus.name)
      .join(', ');
  }
}

/**
 * @deprecated
 */
export function resolveDesiredSectors(
  keyValuesIndustry: KeyValuesIndustry
): SelectItem[] {
  if (!keyValuesIndustry) return [];

  return keyValuesIndustry?.industry.map((industry) => {
    return {
      text: industry.name,
      value: industry.id
    };
  });
}

export function resolveAccordionDesiredRoles(
  desiredRoles: CandidateJobData['desiredRoles']
): AccordionItem[] {
  if (!desiredRoles) return [];

  return desiredRoles.map((role) => {
    return {
      title: role.name,
      subtitle: 'Experience',
      id: role.id,
      customContent: true
    };
  });
}

export function resolveTimeExpectationsItemsById(
  id: number,
  candidateJobSoonArr: CandidateJobSoon[]
): CandidateJobData['time_expectations'] {
  const typeObject = candidateJobSoonArr.find((el) => el.id === id);

  return {
    id: typeObject?.id || id,
    name: typeObject?.name || '',
    it: typeObject?.it || Languages.ITALIAN
  };
}

export const isDeveloperEventBy = (event: Partial<EventMatch>) => {
  const validMatchType = [
    EventType.PROPOSAL_ACCEPTED.toLowerCase(),
    EventType.PROPOSAL_REJECTED.toLowerCase(),
    EventType.OFFER_ACCEPTED.toLowerCase(),
    EventType.OFFER_REJECTED.toLowerCase(),
    EventType.REJECTED_BY_DEV.toLowerCase()
  ];

  return event.type && validMatchType.includes(event.type.toLowerCase());
};

export const isCompanyEventBy = (event: Partial<EventMatch>) => {
  const validMatchType = [
    EventType.REJECTED_BY_COMPANY.toLowerCase(),
    EventType.POSITION_UNPUBLISHED.toLowerCase()
  ];

  return event.type && validMatchType.includes(event.type.toLowerCase());
};

export const resolveMatchEventsBy = (
  event: Partial<EventMatch>
): 'Developer' | 'Talent Partner' | 'Company' => {
  if (isDeveloperEventBy(event) || event.source === 'Developer')
    return 'Developer';

  if (isCompanyEventBy(event) || event.source === 'Company') return 'Company';

  return 'Talent Partner';
};

import type { StatCardData } from '@emotioncod/cm-design-system/dist/types/types';
import { ComputedRef } from 'vue';
import type { Candidate } from '@salvatore0193/definitions/lib/Candidate';
import { EventType } from '@salvatore0193/definitions/lib/Matches/const';
import type { Match, EventMatch } from '@salvatore0193/definitions/lib/Matches';
import {
  resolveCandidatesStats,
  resolveCandidatesTableData
} from '~/resolvers/candidates';
import { filterArrayByPage } from '~/utils';
import type {
  CandidateLogEvents,
  SortableCandidatesArr,
  ClientPagination
} from '~/types/bo-types';
import { resolveCompanyNameFromId } from '~/resolvers/companies';
import { LogEvents } from '~/types/bo-types';
import { resolveMatchEventsBy } from '~/resolvers/candidate';
import { resolveEventType } from '~/resolvers/matches';

export const useCandidatesGetters = defineStore('candidates.getters', () => {
  const candidatesStore = useCandidates();
  const matchesStore = useMatches();

  const getCandidateStats = (
    t: (key: string, ...args: any[]) => string
  ): (StatCardData & { statDescription?: string })[] => {
    if (!candidatesStore.counts) return [];

    return resolveCandidatesStats(candidatesStore.counts, t);
  };

  /**
   * @name getResolveCandidates
   * @description Resolve candidates data for table
   */
  function getCandidatesResolvedTableData(
    t: (key: string, ...args: any[]) => string
  ): SortableCandidatesArr[] {
    if (!candidatesStore.candidates) return [];

    const validCandidates = candidatesStore.candidates.filter(
      (item) => !item?.deleted_at
    );

    const resolveCandidateDataTable = resolveCandidatesTableData(
      validCandidates,
      t
    );

    if (
      candidatesStore.pagination.perPage === undefined ||
      candidatesStore.pagination.currentPage === undefined
    ) {
      return filterArrayByPage(
        resolveCandidateDataTable,
        candidatesStore.pagination.currentPage,
        candidatesStore.pagination.perPage
      );
    }

    return resolveCandidateDataTable;
  }

  /**
   * @name resolveCandidatesPagination
   * @description Customize method for pagination action, if there is a search value we don't show the pagination
   * @param pagination
   * @param isSearched
   */
  const getPagination: ComputedRef<ClientPagination | undefined> = computed(
    () => {
      if (!candidatesStore.pagination) return undefined;

      return {
        ...candidatesStore.pagination,
        currentPage: candidatesStore.pagination.currentPage,
        total: candidatesStore.isSearched
          ? 0
          : candidatesStore.pagination.total,
        changePage: async (page: number) => {
          await candidatesStore.changePage(page);
        }
      };
    }
  );

  const getEventsLogFromCandidate = (): CandidateLogEvents => {
    const matches = matchesStore.matches;

    /**
     * Mock to test the events log for Restitution by Company
     * TODO: Remove this mock when we'll have restitution from companies
     */
    if (matches[0] && Array.isArray(matches[0].events)) {
      matches[0].events.push({
        type: 'Moved to interview',
        source: 'Company',
        content: 'mock nota Yes, request an interview',
        author: {
          email: 'salvatore.esposito@codemotion.it',
          name: 'Salvatore',
          surname: 'IBM',
          uid: '9e0454b4-9937-11eb-9891-5a2452a85487',
          photo: ''
        },
        date: '2023-09-07T10:03:05+00:00'
      } as Partial<EventMatch>);

      matches[0].events.push({
        type: 'Rejected by company',
        source: 'Company',
        content: 'mock nota No, decline',
        author: {
          email: 'salvatore.esposito@codemotion.it',
          name: 'Salvatore',
          surname: 'IBM',
          uid: '9e0454b4-9937-11eb-9891-5a2452a85487',
          photo: ''
        },
        date: '2023-09-07T10:03:05+00:00'
      } as Partial<EventMatch>);

      matches[0].events.push({
        type: 'Moved to offer',
        source: 'Company',
        content: 'mock nota Yes, make offer',
        author: {
          email: 'salvatore.esposito@codemotion.it',
          name: 'Salvatore',
          surname: 'IBM',
          uid: '9e0454b4-9937-11eb-9891-5a2452a85487',
          photo: ''
        },
        date: '2023-09-07T10:03:05+00:00'
      } as Partial<EventMatch>);

      matches[0].events.push({
        type: 'Rejected by company',
        source: 'Company',
        content: 'mock nota No, decline',
        author: {
          email: 'salvatore.esposito@codemotion.it',
          name: 'Salvatore',
          surname: 'IBM',
          uid: '9e0454b4-9937-11eb-9891-5a2452a85487',
          photo: ''
        },
        date: '2023-09-07T10:03:05+00:00'
      } as Partial<EventMatch>);

      matches[0].events.push({
        type: 'Offer Accepted',
        source: 'Company',
        content: 'mock nota Yes, accept the offer',
        author: {
          email: 'salvatore.esposito@codemotion.it',
          name: 'Salvatore',
          surname: 'IBM',
          uid: '9e0454b4-9937-11eb-9891-5a2452a85487',
          photo: ''
        },
        date: '2023-09-07T10:03:05+00:00'
      } as Partial<EventMatch>);

      matches[0].events.push({
        type: 'Offer Rejected',
        source: 'Company',
        content: 'mock nota No, decline',
        author: {
          email: 'salvatore.esposito@codemotion.it',
          name: 'Salvatore',
          surname: 'IBM',
          uid: '9e0454b4-9937-11eb-9891-5a2452a85487',
          photo: ''
        },
        date: '2023-09-07T10:03:05+00:00'
      } as Partial<EventMatch>);
    }

    /**
     * @name logEventsMatches
     * @TODO add logic field "by" when we'll have restitution from companies
     */
    const logEventsMatches = matches.reduce(
      (accumulator: LogEvents[], match: Match) => {
        const jobsName = match.jobPosition?.name || '';
        const jobsId = match.jobPosition?.id || '';
        const companyId =
          match.jobPosition?.company_id || match.jobPosition?.company?.id || '';
        const companyName = resolveCompanyNameFromId(companyId) || '';
        const events = match.events;

        const logEvents =
          events?.map((event) => ({
            jobsName,
            jobsId,
            companyName,
            companyId,
            authorName: event.author?.name
              ? event.author?.name + ' ' + event.author?.surname
              : 'Unknown',
            content: event.content?.toString() || '',
            type: event.type ? resolveEventType(event.type) : '',
            source: event.source?.toString() || '',
            date: event.date?.toString() || '',
            by: resolveMatchEventsBy(event)
          })) || [];

        return [...accumulator, ...logEvents];
      },
      []
    );

    const matchNotes =
      logEventsMatches?.filter(
        (event: LogEvents) =>
          event.type.toLowerCase() === EventType.NOTE.toLowerCase() ||
          event.type.toLowerCase() === EventType.NOTE_ADDED.toLowerCase()
      ) || [];

    const matchEvents =
      logEventsMatches?.filter(
        (event: LogEvents) =>
          event.type.toLowerCase() !== EventType.NOTE.toLowerCase() &&
          event.type.toLowerCase() !== EventType.NOTE_ADDED.toLowerCase()
      ) || [];

    /**
     * @name candidateNotes
     * @description Get candidate notes from candidate events
     * @TODO add logic field "by" when we'll have restitution from companies
     */
    const candidateNotes =
      (candidatesStore.candidate as Candidate).events
        ?.filter((item) => !item?.deleted_at)
        .map((e) => ({
          ...e,
          by: 'Talent Partner',
          authorName: e.author?.name
            ? e.author?.name + ' ' + e.author?.surname
            : 'Unknown'
        })) || [];

    return {
      candidateNotes,
      matchNotes,
      matchEvents
    };
  };

  const getAllCandidateForSelect: ComputedRef<
    { text: string; value: string }[]
  > = computed(() => {
    if (!candidatesStore.candidates) return [];

    return candidatesStore.candidates.map((candidate: Candidate) => ({
      text:
        candidate.name + ' ' + candidate.surname + ' (' + candidate.email + ')',
      value: candidate.id
    }));
  });

  return {
    getCandidateStats,
    getCandidatesResolvedTableData,
    getPagination,
    getEventsLogFromCandidate,
    getAllCandidateForSelect
  };
});
